.logo-icon-container {
    display: flex;
    flex-direction: row;
}

@media screen and (min-width: 801px) {

    #profile-pic {
        height: auto;
        width: 220px;
        margin: 25px 0 10px 30px;
    }

    .about-text {
        font-size: 12pt;
        text-align: left;
        vertical-align: middle;
        padding-left: 60px;
        padding-right: 40px;
        margin-top: 20px;
        margin-bottom: 20px;
      }

    .logo-icon-container {
        width: 220px;
        margin-left: 30px;
        margin-bottom: 5px;
    }

    .logo-icon {
        height: 24px;
        width: 24px;
        margin: 6px 12px 6px 12px;
    }

    .logo-icon:hover {
        transform: scale(1.5);          /* Makes the tile slightly bigger */
        transition: transform 0.3s, box-shadow 0.3s; /* Smooth transition effect */
    }

    .align-items-center {
        align-items: center;
    }

}

@media screen and (max-width: 800px) {
    #profile-pic {
        height: auto;
        width: 100%;
        margin-top: 15px;
        margin-bottom: 15px;
    }

    .about-text {
        font-size: 10pt;
        text-align: left;
        vertical-align: middle;
      }

    .logo-icon-container {
        width: 100%;
        margin-bottom: 15px;
    }
    
    .logo-icon {
        height: 24px;
        width: 24px;
        margin: 6px 18px 6px 18px;
    }
}