#research-summary-container {
    background: rgba(32, 138, 174, 0.1);
    margin-top: 10px;
    margin-bottom: 20px;
}

.research-container {
    align-content: start;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5); /* Dark background */
  z-index: 1;
  display: block; /* Initially hidden */
  cursor: pointer; /* Make the overlay clickable */
}

.tile-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px; /* Adjust the gap between tiles as needed */
}
  
.tile {
  width: calc(25% - 20px); /* For desktop view, 3 tiles in a row with a 20px gap */
  background-color: #ffffff;
	border-radius: 8px;
  box-shadow: 0 8px 12px rgba(0, 0, 0, 0.5); /* Shadow effect */
  padding: 20px;
  text-align: center;
  margin-bottom: 20px; /* Space between rows */
  font-size: 14px;
  font-weight: 600;
}
  
.tile img {
	max-width: 200px;
  height: auto;
}

.popup.active {
  display: block; /* Show the pop-up */
  /* Your other styles for the pop-up */
}

/* Styling for the rest of the page when the pop-up is active */
body.popup-active {
  overflow: hidden; /* Prevent scrolling in the background */
}

/* Apply the overlay and body class when the pop-up is active */
.popup.active + .overlay {
  display: block; /* Show the overlay */
}


@media screen and (min-width: 801px) {

	.research-text {
			font-size: 12pt;
			text-align: left;
			padding: 20px;
			margin-top: 0;
	}

	.research-pic {
			width: 200px;
			height: 200px;
			margin: 10px;
	}

  .tile:hover {
    transform: scale(1.05);          /* Makes the tile slightly bigger */
    box-shadow: 0 10px 15px rgba(0, 0, 0, 0.6); /* Increases the shadow */
    transition: transform 0.3s, box-shadow 0.3s; /* Smooth transition effect */
  }

  .popup {
    /* Your other styles here */
    position: fixed;
    top: 50%; /* Center vertically */
    left: 50%; /* Center horizontally */
    transform: translate(-50%, -50%); /* Center the popup */
    background-color: white; /* White background for the pop-up */
    border-radius: 8px; /* Rounded corners */
    padding: 20px;
    z-index: 1;
    display: flex;
    flex-direction: column;
    min-width: 50vw;
    max-width: 70vw;
    max-height: 80vh; /* Adjust the maximum height as needed */
    overflow-y: auto;
  }
    
  .popup h2 {
    font-size: 24px;
    font-family: 'Open Sans', sans-serif;
    align-self: center;
  }
  
  .popup p {
    font-size: 14px;
  }
  
  .popup ol li {
    font-size: 14px;
    margin: 0 20px 10px 20px;
  }

  .popup ul li {
    font-size: 14px;
    margin: 0 10px 5px 20px;
    font-style: italic;
  }

  .popup-image-container {
    max-width: 100%;
    max-height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: contain;
  }
  
  .popup-image-container img {
    max-width: 60%; /* Set a maximum width for the image */
    max-height: 60%; /* Set a maximum height for the image */
    margin-top: 15px;
    object-fit: contain; /* Maintain aspect ratio and fit within the container */
  }
}

@media screen and (max-width: 800px) {

    .research-pic {
        width: 50%;
        height: auto;
        margin: 10px;
        align-self: center;
    }

    .research-text{
        font-size: 10pt;
        text-align: left;
        padding: 10px;
        margin-top: 0;
      }

    .tile {
        width: 100%; /* For mobile view, one tile in a row */
    }

    .popup {
      /* Your other styles here */
      display: flex;
      position: fixed;
      top: 50%; /* Center vertically */
      left: 50%; /* Center horizontally */
      transform: translate(-50%, -50%); /* Center the popup */
      background-color: white; /* White background for the pop-up */
      border-radius: 12px; /* Rounded corners */
      padding: 10px;
      z-index: 1;
      flex-direction: column;
      width: 90vw;
      max-height: 85vh; /* Adjust the maximum height as needed */
      overflow-y: auto;
    }
      
    .popup h2 {
      font-size: 18px;
      font-family: 'Open Sans', sans-serif;
      align-self: center;
    }
    
    .popup p {
      font-size: 12px;
    }
    
    .popup ol li {
      font-size: 12px;
      margin: 0 20px 10px 20px;
    }

    .popup ul li {
      font-size: 12px;
      margin: 0 20px 10px 20px;
    }
    
    .popup-image-container {
      max-width: 100%;
      max-height: auto;
      display: flex;
      align-items: center;
      justify-content: center;
      overflow: contain;
    }
    
    .popup-image-container img {
      max-width: 90%; /* Set a maximum width for the image */
      max-height: 100%; /* Set a maximum height for the image */
      margin-top: 8px;
      object-fit: contain; /* Maintain aspect ratio and fit within the container */
    }
}