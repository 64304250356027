#wsycs-summary-container {
    background: rgba(32, 138, 174, 0.1);
    margin-top: 10px;
    margin-bottom: 10px;
    align-content: start;
}

.container {
    margin-top: 10px;
    margin-bottom: 10px;
    align-content: start;
    justify-content: center;
}

@media screen and (min-width: 961px) {

    .wsycs-text {
        font-size: 12pt;
        text-align: left;
        padding: 20px;
        margin-top: 0;
    }

    .wsycs-pic {
        width: 30vw;
        margin: 20px 70px;
    }
}

@media screen and (max-width: 960px) {

    .wsycs-pic {
        width: 100%;
        height: auto;
        align-self: center;
        margin: 10px 0;
    }

    .wsycs-text{
        font-size: 10pt;
        text-align: left;
        padding: 10px;
        margin-top: 0;
      }
}