* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

.App {
  text-align: left;
  height: 100vh;
}

.App-header {
  background-color: white;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: black;
}

.main-header {
  display: flex;
  flex-direction: row;
  height: 150px;
  justify-content: center;
  width: 100%;
}

.left {
  margin-right: 10px;
  margin-top: 5px;
  display: flex;
}

#kevin-logo {
  object-fit: contain;
}

.right {
  margin-left: 10px;
  width: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.emphasize-text{
  color: #208AAE;
  font-weight: bold;
}

.horizontal-flex {
  display: flex;
  flex-direction: row;
}

.vertical-flex {
  display: flex;
  flex-direction: column;
}

.center-in-flex {
  justify-content: center;
  align-items: center;
}

a {
  color: #208AAE;
}

.gray {
  color: gray;
}

hr {
  border: 0;
  clear: both;
  display: block;
  background-color: black;
  height: 1px;
  width: 100%;
}

#full-name-header {
  font-size: 48pt;
  margin-top: 0;
  margin-bottom: 0;
  font-family: 'League Spartan', 'Open Sans ExtraBold', sans-serif;
}

#professional-title {
  font-size: 20pt;
  margin-top: 0;
  margin-bottom: 0;
  padding-left: 3px;
  font-family: 'League Spartan', 'Open Sans ExtraBold', sans-serif;
  color: #208AAE;
}

@media screen and (min-width: 961px) {
  .main-header {
    height: 150px;
  }

  #kevin-logo {
    width: 100px;
  }

  #top-hr {
    display: none;
  }

  .main-container {
    margin-left: 10vw;
    margin-right: 10vw;
  }

  #footer-text {
    color: gray;
    padding-top: 1px;
    padding-bottom: 5px;
    text-align: center;
  }
  
  #bottom-hr {
    margin-top: 10px;
  }

}

@media screen and (max-width: 960px) {
  .main-header {
    height: 100px;
  }

  #kevin-logo {
    width: 60px;
  }

  .main-container {
    margin-left: 10vw;
    margin-right: 10vw;
  }

  #full-name-header {
    font-size: max(24pt, 5vw);
    margin-top: 12px;
    margin-bottom: 0;
    font-family: 'League Spartan', 'Open Sans ExtraBold', sans-serif;
  }
  
  #professional-title {
    font-size: max(10pt, 3vw);
    margin-top: 0;
    margin-bottom: 10px;
    font-family: 'League Spartan', 'Open Sans ExtraBold', sans-serif;
    color: #208AAE;
    }

    #footer-text {
      font-size: 10pt;
      color: gray;
      padding-top: 5px;
      padding-bottom: 5px;
      text-align: center;
    }
}
